/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {

  var responsiveProduct = function() {
    if (isTablet() || isMobile()) {
      $('.product-visual').insertBefore('article.products header h1');
    } else if (isSmall()) {
      $('.product-visual').appendTo('article.products header');
    } else {
      $('.product-visual').insertBefore('.content-wrapper .right .other-products');
    }
  }


  /*
   * Responsive breakpoint booleans
   */
  var isDesktop = function() {
    if ($('.responsive-large').css('display') == 'block') {
      return true;
    }
  }
  var isTablet = function() {
    if ($('.responsive-medium').css('display') == 'block') {
      return true;
    }
  }
  var isMobile = function() {
    if ($('.responsive-small').css('display') == 'block') {
      return true;
    }
  }
  var isSmall = function() {
    if ($('.responsive-xsmall').css('display') == 'block') {
      return true;
    }
  }

  /*
   * Redirect to a URL that allows product filtering
   */
  var filterProductList = function() {
    var url = '/product-catalogue/';
    var catNames = $("input[name=categories]:checked").map(function() {
      return this.value;
    }).get();
    if (catNames.length) {
      url += '?filter=';
      for (var i in catNames) {
        url += catNames[i];
        if (i < catNames.length - 1) {
          url += ',';
        }
      }
    }
    window.location = url;
  }

  $(document).ready(function() {
    responsiveProduct();
    video.init();

    $('#nav-toggle-button').click(function() {
      $('.mobile-nav nav').toggleClass('slide-down');
    });

    if ($('.home-slider').length) {
      $('.home-slider').slick({
        dots: true,
        infinite: true,
        speed: 500,
        fade: true,
        autoplay: true,
        cssEase: 'linear',
      });
    }

    if ($('#filterProducts').length) {
      $('#filterProducts').click(function() {
        filterProductList();
      });
    }

  });

  $(window).resize(function() {
    responsiveProduct();
  });


  var video = {
    init: function() {
      $('.video-container').click(function() {
        var video = '<div class="responsive-embed"><iframe  mozallowfullscreen="true" webkitallowfullscreen="true" allowfullscreen="true" src="' + $(this).find('img').attr('data-video') + '?autoplay=1"><iframe></div>"';
        $(this).removeClass('paused').find('img').replaceWith(video);
      });
      $('.video-details').click(function() {
        $(this).parent().toggleClass('active');
      });
    }
  };


})(jQuery); // Fully reference jQuery after this point.
